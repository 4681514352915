exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #22004F */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .meter_green_oUPv2 {\n    fill: rgb(171, 220, 170);\n    stroke: rgb(174, 211, 168);\n} .meter_yellow_3uz-j {\n    fill: rgb(251, 219, 130);\n    stroke: rgb(239, 212, 134);\n} .meter_red_1aMUm {\n    fill: rgb(251, 194, 142);\n    stroke: rgb(235, 189, 142);\n} .meter_mask-container_11m_n {\n    position: relative;\n} .meter_mask_B-cd7 {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    -webkit-transform-origin: top;\n        -ms-transform-origin: top;\n            transform-origin: top;\n    will-change: transform;\n    background: hsla(215, 100%, 95%, 1);\n    opacity: 0.75;\n}\n", ""]);

// exports
exports.locals = {
	"green": "meter_green_oUPv2",
	"yellow": "meter_yellow_3uz-j",
	"red": "meter_red_1aMUm",
	"mask-container": "meter_mask-container_11m_n",
	"maskContainer": "meter_mask-container_11m_n",
	"mask": "meter_mask_B-cd7"
};